@import '../../styles/variables.scss';

body {
  background: #000;
}

#root,
body,
html {
  height: 100%;
}

.homepageLink {
  display: block;
  color: black;
}

img {
  display: block;
}

/* Container */
::selection,
::-moz-selection  {
  background: $color-primary;
}

.future {
  font-family: monospace;
  background: #f3e4e4;
  border: 1px dashed red;
  margin: 40px;
  padding: 40px;
}

/* Container */
.views {
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: black;
  &.-view-is-about{
    background-color: black;
    &:before {
      position: fixed;
      content: '';
      display: block;
      right: 0;
      top: 0;
      width: 36px;
      bottom: 0;
      background-color: black;
    }
  }
}

#io-prismic-toolbar {
  transform: translateX(-97%);
  transition: transform cubic-bezier(.25, .1, .25, 1) 200ms;

  &:hover {
    transform: translateX(0%);
  }
}
