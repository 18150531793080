@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.about__gridwall {
  display: flex;
  flex-wrap: wrap;


  &__item {
    height: 0;
    background-size: cover;
    padding-bottom: 50%;
    position: relative;
    width: 100%;
    margin: 4px;

    @include breakpoint(xs) {
      &.-column--1of3 {
        padding-bottom: 150%;
      }

      &.-column--2of3,
      &.-column--3of3  {
        padding-bottom: 66.66%;
      }
    }

    @include breakpoint(sm) {
      &.-column--1of3 {
        flex-basis: calc(33.3% - 8px);
      }

      &.-column--2of3 {
        flex-basis: calc(66.6% - 8px);
      }

      &.-column--3of3 {
        flex-basis: calc(100% - 8px);
      }
    }
  }

  &__description {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba($black, .8);
    color: $white;
    cursor: pointer;
    opacity: 0;
    transition: opacity $base-duration $ease-standard;

    &__inner {
      transition: transform $midi-duration $ease-standard;
      transform: scale(.9);
    }

    &:hover {
      @include breakpoint(md) {
        opacity: 1;

        .about__gridwall__description__inner {
          transform: scale(1);
        }
      }
    }
  }


}
