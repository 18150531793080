.icon-link {
  padding: 0;
  color: #fff;

  &__bar {
    position: relative;
    cursor: pointer;
    padding: 0 1rem;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: unset;
      width: 20px;
    }
  }
  
  &__text{
      display: flex;
    justify-content: center;
    width: 100%;
  }

  &__inner {
    max-width: 680px;
    width: 100%;
    text-align: center;
  }

}