.accordion {
  padding: 2rem 0;
  color: white;
  &__bar {
    position: relative;
    cursor: pointer;
    padding: 0 1rem;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    transform: translate(50%, -50%);

    &:before, &:after {
      content: '';
      display: block;
      width:8px;
      height: 2px;
      background-color: black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  
  &__text, &__content {
      display: flex;
    justify-content: center;
    width: 100%;
  }

  &__inner {
    max-width: 680px;
    width: 100%;
    text-align: center;
    p {
      line-height: 1.2;
    }
  }

  &__content {
    display: none;
    padding: 1rem;
    box-sizing: border-box;
  }

  &.show {
    .accordion__content {
      display: flex;
    }
    .accordion__icon {
      &:before {
        display: none;
      }
    }
    
  }

}