@import '../../styles/variables.scss';

.casestudy {

  &_animation {
    opacity: 0;
    transition: opacity .3s ease-out .6s;
    &:first-of-type {
      transition: opacity .3s ease-out .3s;
    }
    &.active {
      opacity: 1;
    }
  }

  .-waypointAnim {
    &--fadeIn {
      opacity: 0;
      transition: 600ms opacity $ease-decel 100ms;
  
      &.-enabled {
        opacity: 1;
      }
    }
  }

  &__block {
    position: relative;
    z-index: 10;
    margin-top: $margin-1;
    overflow: hidden;

    &--columns {
      position: relative;
      z-index: 20;
      overflow: visible;
    }

    &.-active {
      opacity: 1;
      transform: translateY(0);
    }

    &.-none {
      margin-top: 0;
    }

    &.-small {
      margin-top: 40px;
    }

    &.-medium {
      margin-top: 80px;
    }

    &.-large {
      margin-top: 120px;
    }

  }

  &__toggle {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    transform: translateX(-15px);

    &.hide {
      opacity: 0;
    }

    @media (max-width: 767px) {
      transform: translateX(0px);
    }

    &.active {
      &:before {
        display: none;
      }
    }

    &:before, &:after {
      content: '';
      display: block;
      width:8px;
      height: 2px;
      background-color: black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

  }

  &__toggle-outer {
    position: absolute;
    left: 0;
    top: -196px;
    width: 45px;
    height: 60px;
    overflow: hidden;
    transition: 600ms $ease-standard;

    

    @media (max-width: 767px) {
      left: unset;
      right: 0;
    }

    @media (max-width: 475px) {
      top: -104px;
    }

  }

  &__footer {
    position: absolute;
    height: 30vh;
    width: 100%;
  }

  &__details {

    transition: 600ms $ease-standard;

    &.hide {
      opacity: 0;
      transform: translate(150px);
    }
    

    button {
      font-family: calibre-regular;
      font-size: 10px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      outline: 0;
      border: 0;
      background-color: transparent;
    }
  }

  &__panel {

    &.active {
      display: flex;
    }
    display: none;
    padding: 6rem 0 2rem;
    justify-content: center;

    &-inner {
      max-width: 680px;
      display: flex;
      padding: 20px;
    }
  }

  &__services {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
    ul{
      margin-left: 0;
      margin-bottom: 20px;
    }
    p{
      margin-top: 0;
      font-family: calibre-regular; 
      font-size: 9px;
      letter-spacing: 2px;
    }
  }
  &__intro {
    width: 70%;
    p{
      margin: 0;
      font-size: 28px;
      line-height: 1.2;

      @media (max-width:700px) {
        font-size: 20px;
      }

      @media (max-width:475px) {
        font-size: 14px;
      }
    }
  }

  &__cta {
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateY(50%);

    &.wp-show {
      a {
        transform: translateY(-50%);  
      }
    }
    a{
      position: absolute;
      transform: translateY(150%);
      transition: 750ms ease-out;
      font-family: calibre-regular;
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: black;
      text-decoration: none;
      background-color: #C8FF54;
      padding: 20px 50px;
      border-radius: 50px;;
    }
  }
}


