@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.work-filters-outer{
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.work-filters-background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  cursor: pointer;
}

.work-filter-button {
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  width: 75px;
  height:75px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;

  @media (max-width: 475px) {
    display: none;
  }

  button {
    outline: 0;
    border: 0;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    cursor: pointer;
    font-family: calibre-light;
    color: black;
    background-color: transparent;

  }
}
.work-filters{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

  &__content {
    display: flex;
    flex-direction: column;
    padding: 50px 100px 100px 50px;
    @media (max-width: 475px) {
      padding: 40px 7vw;
    }
    
  }

  &__close {
    position: absolute;
    bottom: 28px;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    cursor: pointer;
    font-family: calibre-light;
    right: 20px;
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    @media (max-width: 475px) {
      display: none;
    }
  }

  &__buttons{
    display: flex;
    flex-direction: column;
    width: 100%;


    button {
      padding: 0;
      text-align: left;
      display: block;
      margin-top: 10px;
      min-width: 70px;;
      width: 100%;
      outline: 0;
      border: 0px;
      font-size: 12px;
      background: transparent;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__filter {
    @media (max-width: 475px) {
      font-size: 5.5vw;
    }
    width: 100%;
    font-family: calibre-light;
    font-size: 36px;
    line-height: 1.2;
    opacity: .6;
    span {
      font-size: .6em;
      display: inline-block;
      margin-left: 10px;
      transition: .3s;
    }
    &:hover {
      span {
        transform: translateX(-6px);
      }
    }
    cursor: pointer;
    &.active{
      opacity: 1;
    }
  }
}


.fade-slide-filters-enter {
  opacity: 0;
  .work-filters {
    transform: translateX(100%);
    opacity: 0;
  }
}
.fade-slide-filters-enter-active {
  opacity: 1;
  transition: all $base-duration cubic-bezier(0, 0, .2, 1);
  .work-filters {
    transform: translateX(0%);
    opacity: 1;
    transition: all $base-duration cubic-bezier(0, 0, .2, 1);
  }
}
.fade-slide-filters-exit {
  .work-filters {
    transform: translateX(0%);
    opacity: 1;
  }
  opacity: 1;
}
.fade-slide-filters-exit-active {
  .work-filters {
    transform: translateX(100%);
    transition: all $base-duration cubic-bezier(0, 0, .2, 1);
    opacity: 0;
  }
  opacity: 0;
  transition: all $base-duration cubic-bezier(0, 0, .2, 1);
}