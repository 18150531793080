@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.secondaryNav {
  background-color: transparent;
  left: 0;
  padding-top: $nav-padding;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1005;
  box-sizing: border-box;
  transition: transform .3s;
  z-index: 100;

  @media (max-width: 475px) {
    display: none;
  }
  

  @include breakpoint(xs) {
    padding-top: $nav-padding-xs;
  }

  .secondaryNav__item {
    transition: .3s;
    &.hide {
      opacity: 0;
    }
  }

  .secondaryNav__item a, .secondaryNav__item button{
    background-color: white;
    color: black;
    border-radius: 20px;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: calibre-light, arial, sans-serif;
    font-weight: 700;
    border: 1px solid black;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background-color: rgba(255,255,255,.85);
    }
  }

  &.-wrap-nav{
    padding: 20px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &.is-subpage {
    transform: translateY(100%);
  }

  &.-view-is-about {
    transform: translateY(100%);
    .icon--grid {
      opacity: 0; // hide 'work' link when view is 'about'
      visibility: hidden;
    }
  }

  &.-view-is-shop {
    transform: translateY(100%);
    .icon--about {
      opacity: 0; // hide 'about' link when view is 'work'
      visibility: hidden;
    }
  }
}
