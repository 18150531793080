@font-face {
    font-family: calibre-light;
    src: url( "https://prismic-io.s3.amazonaws.com/thisstaging%2F75524f87-37a9-489b-94e1-9a2cf1e5d910_calibreweb-light.woff" ) format( "woff" );
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: calibre-regular;
    src: url( "https://prismic-io.s3.amazonaws.com/thisstaging%2F1af5ff39-503a-4dd7-927b-0bf77ca4e075_calibreweb-regular.woff" ) format( "woff" );
    font-style: normal;
    font-stretch: normal;
}

/* Tiempos Headline Light */
@font-face {
    font-family: serif-headline;
    src: url( "https://prismic-io.s3.amazonaws.com/thisstaging%2Fab414be7-030e-4556-9023-8061be7fa479_tiemposheadlineweb-light.woff" ) format( "woff" );
    font-style: normal;
    font-stretch: normal;
}

/* Tiempos Headline Light Italic */
@font-face {
    font-family: serif-headline;
    src: url( "https://prismic-io.s3.amazonaws.com/thisstaging%2F962ea27b-6e9b-47db-b3ac-136b7cd782c2_tiemposheadlineweb-lightitalic.woff" ) format( "woff" );
    font-style: italic;
    font-stretch: normal;
}

/* Domaine Display Black */
@font-face {
    font-family: 'domaineDiaplay-black';
    src: url( "https://prismic-io.s3.amazonaws.com/thisstaging%2F12855606-5254-41ca-8832-a6ad659c0643_domainedisplayweb-black.woff" ) format( "woff" );
    font-style: normal;
    font-stretch: normal;
}
