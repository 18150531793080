$margin-1: 4em;

$nav-padding: 80px;
$nav-padding-xs: 40px;

$color-primary: #d59680; // Peach
$color-secondary: #ffa500;
$color-grey: #797979;
$color-grey-lt: #dadada;

$case-study-bg: #f9f9f9;

$white: #fff;
$black: #000;

/******************************************************************************
 * Animations
*******************************************************************************/

$ease-standard: cubic-bezier(.4, 0, .2, 1);
$ease-decel: cubic-bezier(0, 0, .2, 1);
$ease-accel: cubic-bezier(.4, 0, 1, 1);

$ease-views: cubic-bezier(0, 0, .2, 1);
$ease-views-rev: cubic-bezier(8, 0, 1, 1);

$long-duration: 600ms;
$base-duration: 400ms;
$midi-duration: 300ms;
$fast-duration: 200ms;

$anim-x-slow: 1800ms;
$anim-slow: 1200ms;
$anim-med: 900ms;
$anim-fast: 400ms;
$anim-x-fast: 200ms;
$anim-xx-fast: 100ms;
