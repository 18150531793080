.loader {
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;

  &.no-background {
    background: transparent;
  }
}

.loader__icon {
  border-radius: 20px;
  mix-blend-mode: difference;
  &.has-background {
    background: #fff;
  }
  transform: scale(.5)
}
